<template class="page">
  <div id="app">
    <div class="logocenter">
    <v-img contain :src="require('@/assets/Logo spizes.png')" class="logo"/>
    </div>
    <v-toolbar dense class="bar pa-0" style="background-color: black">

    </v-toolbar>
  <v-container class="page pa-0">
    <div>
      <v-img contain :src="getImage" class="image"/>
      <h3 class="tekstmain" :class="getTekstClass">
        <h4>SPIZES</h4>
          <br/>Binnenkort kunt u genieten van onze wisselende authentieke gerechten met een Caribische & Aziatische touch. Vanuit Voorschoten maken wij met veel liefde en passie gerechten die u met uw familie en vrienden thuis kunt eten: Echt “homemade for home”. Denk hier bij aan onder andere saté, masala krab (volgens oma’s recept), roti kip, paneer, daal en sauto soep. Bijna elk gerecht maken wij ook in een vega of zelfs vegan variant.
        <br/>
        <br/>
        In ons catering-op-maat concept staat het gebruik van verse ingrediënten en kruiden centraal. Daarnaast willen wij zo min mogelijk voedsel verspillen.
        <br>
        <br>
        Meer info &#9743; 06 2160 8243
      </h3>
    </div>
  </v-container>
  </div>
</template>

<script>
export default {
  computed: {
    getImage () {
      // console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return require('@/assets/spizes_achtergrond_small.png');
      }
      return require('@/assets/Spices achtergrond.png');
    },

    getWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'width: 90%;';

        default: return 'width: 50%;';
      }
    },
    getTekstClass() {
      return 'tekst'+this.$vuetify.breakpoint.name;
    },

  },
}
</script><style lang="scss" scoped>
.page {
  color: white;
  background-color: black;
}

.bar {
  background-color: black;
}
.logocenter{
  position: absolute;
  width: 100%;
}
.logo{
  width: 100px;
  height: 100px;
  z-index: 2;
  margin: auto;
}
.image{
  opacity: 1;
}

.tekstmain{
  position: absolute;
}
.tekstxs {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 1em;
  top: 100px;
  left: 10%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.tekstsm {
  font-size: .9em;
  font-family: sans-serif;
  line-height: 1.1;
  text-align: center;
  top: 110px;
  left: 40%;
  width: 50%;
  max-width: 500px;
}

.tekstmd {
  font-size: 1.1em;
  font-family: sans-serif;
  line-height: 1.4;
  text-align: center;
  top: 130px;
  left: 40%;
  width: 50%;
  max-width: 500px;
}

.tekstlg {
  font-size: 1.3em;
  font-family: sans-serif;
  line-height: 1.4;
  text-align: center;
  top: 130px;
  left: 40%;
  width: 50%;
  max-width: 500px;
}

.tekstxl {
  font-size: 1.6em;
  font-family: sans-serif;
  line-height: 1.5;
  text-align: center;
  top: 130px;
  left: 40%;
  width: 50%;
  max-width: 600px;
}

</style>
